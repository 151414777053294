import React from 'react';
import Moment from 'moment';

import Link from 'utils/localized-link';

import Banner from 'components/sections/banner';
import Layout from 'components/layout';
import BasicLayout from 'components/basic-layout';
//import P24101 from '@youship/legal/projects/p24101';
import SEO from 'components/seo';
//import Image from 'components/image';
import deliveryVan from '../../images/delivery-van.png';
import pt2020Image from '../../images/pt2020.png';

const bannerImage = 'home/robot-banner-bottom2.jpg';

const bannerButtons = [
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts'
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'expertise.banner.buttons.contacts'
  }
];

const FinancingPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="projects.title" />

    <BasicLayout>
      <>
      <div className="">
          <img
                alt="PT2020"
                src={pt2020Image}
              />
       </div>  
      <div>
        <p className="b-layout__center">
          Designação do projeto: YOUSHIP<br/>
          Código do projeto: CENTRO-02-0752-FEDER-024101<br/>
          Objetivo principal: OT 3 - Reforçar a competitividade das PME<br/>
          Região de intervenção: Centro<br/>
          Entidade beneficiária: SHIPNOW TECHNOLOGIES, UNIPESSOAL LDA<br/>
          Data de aprovação: 2017-08-08<br/>
          Data de início: 2017-06-15<br/>
          Data de conclusão: 2021-06-14<br/>
          Custo total elegível: 107.464,38 EUR<br/>
          Apoio financeiro da União Europeia: FEDER 48.358,97 EUR<br/>
        </p>
      </div>
      
      <div>
        <p className="b-layout__center">
          O projecto YOUSHIP pretende facilitar o envio de encomendas, promovendo o transporte sustentável e a melhoria da qualidade de vida nas cidades.
        </p>
          <img
                alt="Delivery Van"
                src={deliveryVan}
              />
        <p>
          <a href="/site2/blog/sms">Campanha SMS</a>
        </p>
        <p>
          <a href="/site2/blog/fb">Campanha Facebook</a>
        </p>
      </div>
    </>
    </BasicLayout>
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      title="home.banner.title"
    />

  </Layout>
);

export default FinancingPage;
